import "./index.css";
import React, { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { FaPlus, FaTrash } from "react-icons/fa";

import { useUpdateNodeInternals } from "reactflow";

import nameConditions from "../../../helpers/nameConditions";

import AddVariablesInText from "../AddVariablesInText";
import { CreateTask } from "./CreateTask";
import { validateTaskData } from "../../../helpers/validate-task-data";
import { CreateDeal } from "./CreateDeal";
import { validateDealData } from "../../../helpers/validate-deal-data";
import { handleHeaderByType } from "../../../helpers/header-action";
import { toast } from "react-toastify";

function Modal(props) {
	const updateNodeInternals = useUpdateNodeInternals();
	//webhook
	const [webhookMethod, setwebhookMethod] = useState("POST");
	const [webhookHeaders, setWebhookHeaders] = useState([]);
	// const [webhookUrl, setwebhookUrl] = useState("");
	// const [webhookBody, setwebhookBody] = useState("");

	//question
	// const [question, setquestion] = useState("");
	const [questionVar, setquestionVar] = useState("");
	const [questionTypeResponse, setQuestionTypeResponse] = useState("simple");
	const [questionResponseActions, setQuestionResponseActions] = useState({});

	//conditional
	const [condVar, setcondVar] = useState("");
	const [condType, setcondType] = useState("");
	// const [condValue, setcondValue] = useState("");
	const [condValueVisible, setcondValueVisible] = useState(true);

	//gush
	const [gushSector, setgushSector] = useState({});
	const [gushOperators, setgushOperators] = useState([]);
	const [gushChoose, setgushChoose] = useState("sector");

	//jump
	const [jumpVar, setjumpVar] = useState("");
	const [jumps, setjumps] = useState({});

	//setvar
	const [varToSet, setvarToSet] = useState("");
	const [valueToset, setvalueToset] = useState("");

	const [messageWarning, setmessageWarning] = useState("");
	const [inputsSaveResp, setinputsSaveResp] = useState([]);
	const [webhookVars, setwebhookVars] = useState({});
	const [webhookResps, setwebhookResps] = useState({});
	const [objectData, setObjectData] = useState({});

	//sleep
	const [sleepSeconds, setSleepSeconds] = useState(0);

	const msgMessage = useRef(null);
	const webhookUrl = useRef(null);
	const webhookBody = useRef(null);
	const question = useRef(null);
	const condValue = useRef(null);

	useEffect(() => {
		if (["ik", "cpf", "cnpj", "number", "date"].includes(condType)) {
			setcondValueVisible(false);
			condValue.current.textContent = "";
		} else {
			setcondValueVisible(true);
		}
	}, [condType]);

	const checkVarExists = (v) => {
		v = v.replace("{{", "");
		v = v.replace("}}", "");
		if (props.variables.find((i) => i.name == v)) {
			return true;
		}
		return false;
	};

	const handleContentToPut = (content) => {
		content = content.replaceAll("\r\n", "<br>");
		content = content.replaceAll("\n", "<br>");

		let regexVars = new RegExp(`{{([^}]*)}}`, "gm");
		let result;
		let c2 = content;
		while ((result = regexVars.exec(c2))) {
			const varToReplace = result[0];
			if (checkVarExists(varToReplace)) {
				content = content.replaceAll(
					varToReplace,
					`<span contenteditable="false" class="variableInText">${varToReplace}</span>`
				);
			}
		}

		return content;
	};

	useEffect(() => {
		if (!props.active) {
			setObjectData({});
			setquestionVar("");
			setQuestionTypeResponse("simple");
			setQuestionResponseActions({});
			setcondVar("");
			setcondType("");
			setcondValueVisible(true);
			setjumpVar("");
			setjumps({});
			setvarToSet("");
			setvalueToset("");
			setinputsSaveResp([]);
			setwebhookVars({});
			setwebhookResps({});
			setSleepSeconds(0);
			setWebhookHeaders([]);
		}
	}, [props.active]);

	useEffect(() => {
		if (msgMessage.current) {
			let x = handleContentToPut(
				props.nodeToEdit?.msgMessage ? props.nodeToEdit.msgMessage : ""
			);

			if (x && x.includes("delay")) {
				x = x.replace("<delay>", "&lt;delay&gt;");
				x = x.replace("</delay>", "&lt;/delay&gt;");
			}

			msgMessage.current.innerHTML = x;
		}

		if (webhookUrl.current) {
			webhookUrl.current.innerHTML = handleContentToPut(
				props.nodeToEdit?.webhookUrl ? props.nodeToEdit.webhookUrl : ""
			);
		}

		if (webhookBody.current) {
			webhookBody.current.innerHTML = handleContentToPut(
				props.nodeToEdit?.webhookBody ? props.nodeToEdit.webhookBody : ""
			);
		}

		if (question.current) {
			let x = handleContentToPut(
				props.nodeToEdit?.question ? props.nodeToEdit.question : ""
			);

			if (x && x.includes("delay")) {
				x = x.replace("<delay>", "&lt;delay&gt;");
				x = x.replace("</delay>", "&lt;/delay&gt;");
			}

			question.current.innerHTML = x;
		}

		if (condValue.current) {
			condValue.current.innerHTML = handleContentToPut(
				props.nodeToEdit?.condValue ? props.nodeToEdit.condValue : ""
			);
		}

		setObjectData(props.nodeToEdit.objectData || {});

		const varsSave = props.nodeToEdit?.webhookSaveVars;
		const webhookHeadersSave = props.nodeToEdit?.webhookHeaders || [];
		const inputs = [];
		let xResps = {};
		let xVars = {};
		if (varsSave) {
			for (let i = 0; i < varsSave.length; i++) {
				inputs.push(i);
				xResps = {
					...xResps,
					[i]: varsSave[i].varJson,
				};
				xVars = {
					...xVars,
					[i]: varsSave[i].varBot,
				};
			}
		}

		setinputsSaveResp(inputs);
		setwebhookResps(xResps);
		setwebhookVars(xVars);
		if (webhookHeadersSave.length) {
			setWebhookHeaders(
				webhookHeadersSave.map((webhook) => {
					return {
						...webhook,
						id: webhook._id,
					};
				})
			);
		}

		const jumpsNode = props.nodeToEdit?.jumps;
		let xJumps = {};
		if (jumpsNode) {
			for (let i = 0; i < jumpsNode.length; i++) {
				if (jumpsNode[i] == "default") {
					continue;
				}
				xJumps = {
					...xJumps,
					[i]: jumpsNode[i],
				};
			}
		}
		setjumps(xJumps);
		setjumpVar(props.nodeToEdit?.jumpVar ? props.nodeToEdit.jumpVar : "email");

		setvarToSet(
			props.nodeToEdit?.varToSet ? props.nodeToEdit.varToSet : "email"
		);
		setvalueToset(
			props.nodeToEdit?.valueToset ? props.nodeToEdit.valueToset : ""
		);

		setquestionVar(
			props.nodeToEdit?.questionVar ? props.nodeToEdit.questionVar : ""
		);

		setSleepSeconds(
			props.nodeToEdit?.sleepSeconds ? props.nodeToEdit.sleepSeconds : 0
		);

		setQuestionTypeResponse(
			props.nodeToEdit?.questionTypeResponse
				? props.nodeToEdit.questionTypeResponse
				: "simple"
		);

		setgushChoose(props.nodeToEdit?.gushChoose || "sector");
		setgushOperators(props.nodeToEdit?.gushOperators || []);

		if (
			props.nodeToEdit?.questionButtons &&
			props.nodeToEdit?.questionTypeResponse == "buttons"
		) {
			const auxBtn = {};
			props.nodeToEdit.questionButtons.forEach((x) => {
				if (x.id == 0) {
					return;
				}
				auxBtn[x.id] = x.textButton;
			});

			setQuestionResponseActions(auxBtn);
		}

		if (
			props.nodeToEdit?.questionList &&
			props.nodeToEdit?.questionList?.sections &&
			props.nodeToEdit?.questionTypeResponse == "list"
		) {
			const auxList = { sections: {} };

			let sectionId = 1;
			props.nodeToEdit.questionList.sections.forEach((sec) => {
				if (sec.sectionTitle == "default") {
					return;
				}
				const items = {};
				sec.items.forEach((it) => {
					const id = it.id.split("-")[3];
					items[id] = {
						id: it.id,
						title: it.title,
						description: it.description,
					};
				});

				auxList.sections[sectionId] = {
					title: sec.sectionTitle,
					items,
				};
				sectionId++;
			});

			if (props.nodeToEdit.questionList?.buttonTitle) {
				auxList.buttonTitle = props.nodeToEdit.questionList?.buttonTitle;
			}

			setQuestionResponseActions(auxList);
		}
		let tempSectorGush = "";
		if (props.sectors && props.sectors[0]) {
			tempSectorGush = props.sectors[0];
		}

		setgushSector(
			props.nodeToEdit?.gushSector
				? props.nodeToEdit.gushSector
				: tempSectorGush
		);

		let tempCondVar = "";
		if (props?.variables[0]?.name) {
			tempCondVar = props.variables[0].name;
		}
		setcondVar(
			props.nodeToEdit?.condVar ? props.nodeToEdit?.condVar : tempCondVar
		);
		setcondType(props.nodeToEdit?.condType ? props.nodeToEdit.condType : "eq");
	}, [props.nodeToEdit, msgMessage.current]);

	const addInputJump = () => {
		const idx = String(new Date().getTime());
		setjumps({ ...jumps, [idx]: "" });
	};

	const removeJump = (remove) => {
		const x = { ...jumps };
		delete x[remove];
		setjumps(x);
	};

	const addInputResp = () => {
		const x = inputsSaveResp[inputsSaveResp.length - 1];
		const y = x ? x : 0;
		setinputsSaveResp(inputsSaveResp.concat(y + 1));
	};

	const countItemsList = () => {
		let c = 0;
		Object.keys(questionResponseActions.sections).forEach((sec) => {
			c = c + Object.keys(questionResponseActions.sections[sec].items).length;
		});
		return c;
	};

	const addItemSectionQuestion = (x) => {
		if (countItemsList() == 10) {
			return alert("O limite de itens é 10");
		}

		let newItemId = 1;
		if (questionResponseActions.sections[x].items) {
			newItemId =
				parseInt(
					Object.keys(questionResponseActions.sections[x].items)[
						Object.keys(questionResponseActions.sections[x].items).length - 1
					]
				) + 1;
		}

		if (isNaN(newItemId)) {
			newItemId = 1;
		}

		questionResponseActions.sections[x].items[newItemId] = {
			id: `sec-${x}-item-${newItemId}`,
			title: "",
			description: "",
		};

		setQuestionResponseActions({ ...questionResponseActions });
	};

	const addSectionListQuestion = () => {
		let newSectorId = 1;
		if (questionResponseActions && questionResponseActions.sections) {
			newSectorId =
				parseInt(
					Object.keys(questionResponseActions.sections)[
						Object.keys(questionResponseActions.sections).length - 1
					]
				) + 1;
		} else {
			questionResponseActions["sections"] = {};
		}

		questionResponseActions.sections[newSectorId] = {
			title: "",
			items: {
				1: {
					id: `sec-${newSectorId}-item-1`,
					title: "",
					description: "",
				},
			},
		};

		setQuestionResponseActions({ ...questionResponseActions });
	};

	const addInputButton = () => {
		let itemsAction = Object.keys(questionResponseActions);

		let newId = 1;
		if (itemsAction.length) {
			newId = parseInt(itemsAction[itemsAction.length - 1]) + 1;
		}

		if (Object.keys(questionResponseActions).length > 2) {
			return alert("Você só pode adicionar 3 botões");
		}

		setQuestionResponseActions({
			...questionResponseActions,
			[newId]: "",
		});
	};

	const removeInputButton = (x) => {
		let newActions = questionResponseActions;
		delete newActions[x];

		setQuestionResponseActions({
			...newActions,
		});
	};

	const removeSession = (x) => {
		delete questionResponseActions.sections[x];
		setQuestionResponseActions({
			...questionResponseActions,
		});
	};

	const removeSessionItem = (x, it) => {
		delete questionResponseActions.sections[x].items[it];

		setQuestionResponseActions({
			...questionResponseActions,
		});
	};

	const removeInputResp = (i) => {
		const x = inputsSaveResp.filter((ipvar) => ipvar !== i);
		setinputsSaveResp(x);

		delete webhookVars[i];
		setwebhookVars(webhookVars);

		delete webhookResps[i];
		setwebhookResps(webhookResps);
	};

	const handleContentToSave = (html) => {
		html = html.replaceAll("<div><br></div>", "\r\n");
		html = html.replaceAll("<br>", "\r\n");
		html = html.replaceAll("<div>", "\r\n");
		html = html.replaceAll("&nbsp;", " ");
		html = html.replaceAll(/(<([^>]+)>)/gi, ""); // Hello World
		html = html.replaceAll("&amp;", "&");
		return html;
	};

	const saveNode = () => {
		if (props.nodeToEdit.type === "message") {
			props.saveNode({
				ref: props.nodeToEdit.ref,
				msgMessage: handleContentToSave(msgMessage.current.innerHTML),
			});
		}

		if (props.nodeToEdit.type === "gush") {
			let gushObject = {
				ref: props.nodeToEdit.ref,
				gushSector,
				gushChoose,
				gushOperators,
			};

			if (gushChoose === "operator") {
				gushObject.gushSector = null;
			}

			if (gushChoose === "sector") {
				gushObject.gushOperators = [];
			}

			props.saveNode(gushObject);
		}

		if (props.nodeToEdit.type === "sleep") {
			props.saveNode({ ref: props.nodeToEdit.ref, sleepSeconds });
		}

		if (props.nodeToEdit.type === "setvar") {
			props.saveNode({ ref: props.nodeToEdit.ref, varToSet, valueToset });
		}

		if (props.nodeToEdit.type === "webhook") {
			const webhookSaveVars = [];

			if (
				!webhookVars ||
				!webhookResps ||
				webhookVars.length != webhookResps.length
			) {
				return alert("Preencha todos os campos");
			}

			if (webhookHeaders.some((header) => !header.label)) {
				toast.warn("Preencha corretamente os Headers do Webhook.");
				return;
			}

			webhookHeaders.forEach((header) => {
				const headerValue = document.querySelector(
					`[data-header-id="${header.id}"]`
				);
				header.value = headerValue.textContent;
			});

			Object.keys(webhookVars).forEach((i) => {
				webhookSaveVars.push({
					varJson: webhookResps[i],
					varBot: webhookVars[i],
				});
			});

			props.saveNode({
				ref: props.nodeToEdit.ref,
				webhookUrl: handleContentToSave(webhookUrl.current.innerHTML),
				webhookBody: handleContentToSave(webhookBody.current.innerHTML),
				webhookMethod,
				webhookSaveVars,
				webhookHeaders,
			});
		}

		if (props.nodeToEdit.type === "jump") {
			updateNodeInternals(props.nodeToEdit.ref);
			let jumpsSave = [];

			Object.keys(jumps).forEach((x) => {
				jumpsSave.push(jumps[x]);
			});

			if (jumpsSave.includes("default")) {
				jumpsSave = jumpsSave.filter((x) => x != "default");
			}
			jumpsSave.push("default");

			const jumpsEdited =
				props.nodeToEdit?.jumps?.filter((j) => !jumpsSave.includes(j)) || [];

			props.setEdges((eds) => {
				const ids = [];
				jumpsEdited.forEach((b) => {
					const found = eds.find(
						(a) => a.source === props.nodeToEdit.ref && a.sourceHandle == b
					);

					if (found) {
						ids.push(found.id);
					}
				});

				const newEdges = eds.filter((edge) => !ids.includes(edge.id));

				return newEdges;
			});

			props.saveNode({
				ref: props.nodeToEdit.ref,
				jumpVar,
				jumps: jumpsSave,
			});
		}

		if (props.nodeToEdit.type === "question") {
			let questionButtons = [];
			let questionList = {};

			if (questionTypeResponse === "buttons") {
				questionButtons = Object.keys(questionResponseActions).map((x) => {
					return {
						id: x,
						textButton: questionResponseActions[x],
					};
				});
				questionButtons = questionButtons.filter((x) => x.id != 0);
				questionButtons.push({ id: 0, textButton: "default" });
			}
			if (questionTypeResponse === "list" && questionResponseActions.sections) {
				questionList["buttonTitle"] =
					questionResponseActions?.buttonTitle || "Selecionar opção";
				questionList["sections"] = Object.keys(
					questionResponseActions.sections
				).map((x) => {
					return {
						sectionTitle: questionResponseActions.sections[x].title,
						items: Object.keys(questionResponseActions.sections[x].items).map(
							(it) => {
								return {
									id: questionResponseActions.sections[x].items[it].id,
									title: questionResponseActions.sections[x].items[it].title,
									description:
										questionResponseActions.sections[x].items[it].description,
								};
							}
						),
					};
				});

				questionList["sections"] = questionList["sections"].filter(
					(x) => x.sectionTitle != "default"
				);
				questionList["sections"].push({
					sectionTitle: "default",
					items: [
						{
							id: "list-0",
							title: "",
							description: "",
						},
					],
				});
			}

			const questionText = handleContentToSave(
				question.current.innerHTML
			).replaceAll("&amp;", "&");

			props.saveNode({
				ref: props.nodeToEdit.ref,
				question: questionText,
				questionVar,
				questionTypeResponse,
				questionButtons,
				questionResponseActions,
				questionList,
			});
		}

		if (props.nodeToEdit.type === "conditional") {
			props.saveNode({
				ref: props.nodeToEdit.ref,
				condVar,
				condType,
				condValue: handleContentToSave(condValue.current.innerHTML),
			});
		}

		if (props.nodeToEdit.type === "createtask") {
			const isTaskValid = validateTaskData();
			if (isTaskValid === false) return;
			props.saveNode({
				ref: props.nodeToEdit.ref,
				objectData: isTaskValid,
			});
		}

		if (props.nodeToEdit.type === "createdeal") {
			const isDealValid = validateDealData();
			if (isDealValid === false) return;
			props.saveNode({
				ref: props.nodeToEdit.ref,
				objectData: isDealValid,
			});
		}
	};

	const formatJson = () => {
		try {
			const x = JSON.parse(webhookBody.current.textContent);
			const y = JSON.stringify(x, undefined, 4);
			webhookBody.current.innerHTML = handleContentToPut(y);

			setmessageWarning("");
		} catch (e) {
			if (webhookBody != "") {
				setmessageWarning("json inválido");
			} else {
				setmessageWarning("");
			}
		}
	};

	const addWebhookHeader = () => {
		setWebhookHeaders((headers) => {
			return [
				...headers,
				{
					value: "",
					label: "",
					id: Date.now(),
				},
			];
		});
	};

	const handleRemoveHeader = (header) => {
		setWebhookHeaders((headers) => {
			const remainHeaders = headers.filter((h) => h.id !== header.id);
			return remainHeaders;
		});
	};

	const onchangeVarsResps = (x) => {
		const id = x.id.split("-")[1];
		if (x.id.includes("var")) {
			setwebhookVars({ ...webhookVars, [id]: x.value });
		} else {
			setwebhookResps({ ...webhookResps, [id]: x.value });
		}
	};

	const onchangeJump = (x) => {
		const id = x.id.split("-")[1];
		setjumps({ ...jumps, [id]: x.value });
	};

	const onchangeRespButtons = (x) => {
		const id = x.id.split("-")[2];
		if (x.value && x.value.length > 20) {
			x.value = x.value.substring(0, 20);
		}
		setQuestionResponseActions({ ...questionResponseActions, [id]: x.value });
	};

	const onchangeSectionTitle = (x) => {
		const id = x.id.split("-")[1];
		if (x.value && x.value.length > 20) {
			x.value = x.value.substring(0, 20);
		}

		questionResponseActions.sections[id].title = x.value;
		setQuestionResponseActions({ ...questionResponseActions });
	};

	const onchangeButtonTitle = (x) => {
		const sectionId = x.id.split("-")[1];
		const itemId = x.id.split("-")[3];

		if (x.value && x.value.length > 24) {
			x.value = x.value.substring(0, 24);
		}

		questionResponseActions.sections[sectionId].items[itemId].title = x.value;
		setQuestionResponseActions({ ...questionResponseActions });
	};

	const onchangeButtonDescription = (x) => {
		const sectionId = x.id.split("-")[1];
		const itemId = x.id.split("-")[3];

		if (x.value && x.value.length > 72) {
			x.value = x.value.substring(0, 72);
		}

		questionResponseActions.sections[sectionId].items[itemId].description =
			x.value;
		setQuestionResponseActions({ ...questionResponseActions });
	};

	const onchangeButtonSectionTitle = (x) => {
		questionResponseActions.buttonTitle = x.value;
		setQuestionResponseActions({ ...questionResponseActions });
	};

	const handlePaste = (e) => {
		e.preventDefault();
		document.execCommand(
			"inserttext",
			false,
			e.clipboardData.getData("text/plain")
		);
	};

	function handleWebhookHeaders(currentHeader, action) {
		return (e) => {
			setWebhookHeaders((headers) => {
				return headers.map((header) => {
					const headerFound = header.id === currentHeader.id;
					if (headerFound) {
						header[action] = e.target.value;
					}

					return header;
				});
			});
		};
	}

	return (
		<>
			<div className={`modalBlackout ${props.active ? "" : "modalHide"}`}></div>
			<div className={`modalDiv ${props.active ? "" : "modalHide"}`}>
				<div className="modalHeader">
					{handleHeaderByType(props.nodeToEdit.type)}
					<div
						className="modalHeader-close"
						onClick={() => {
							props.saveNode(props.nodeToEdit);
							props.handleModalEdit();
						}}
					>
						<MdClose size={22} />
					</div>
				</div>
				<div className="modalContent">
					{props.nodeToEdit.type == "message" && (
						<>
							<label>Escreva uma mensagem:</label>
							<div
								ref={msgMessage}
								contentEditable="true"
								className="inputTextArea"
								onPaste={handlePaste}
							></div>
							<AddVariablesInText
								variables={props.variables}
							></AddVariablesInText>
						</>
					)}

					{props.nodeToEdit.type == "gush" && (
						<div className="node-question">
							<div>
								<label>Tipo de transferência</label>
								<select
									className="inputText"
									value={gushChoose}
									onChange={(e) => {
										setgushChoose(e.target.value);
										if (!gushChoose) {
											setgushSector(props.sectors[0]);
										}
										if (!gushOperators?.[0]) {
											setgushOperators([props?.operators?.[0]?._id]);
										}
									}}
								>
									<option value="sector">Por setor</option>
									<option value="operator">Por operador</option>
								</select>
							</div>
							{gushChoose === "sector" ? (
								<div>
									<label>Selecione um setor para transferir:</label>
									<select
										className="inputText"
										value={gushSector}
										onChange={(e) => setgushSector(e.target.value)}
									>
										{props.sectors.map((sec) => (
											<option value={sec}>{sec}</option>
										))}
									</select>
								</div>
							) : (
								<div>
									<label>Selecione um operator para transferir:</label>
									<select
										className="inputText"
										value={gushOperators?.[0]}
										onChange={(e) => setgushOperators([e.target.value])}
									>
										{props?.operators?.map((sec) => (
											<option value={sec._id}>
												{sec.name} ({sec.sector?.name})
											</option>
										))}
									</select>
								</div>
							)}
						</div>
					)}

					{props.nodeToEdit.type == "createtask" && (
						<CreateTask
							setObjectData={setObjectData}
							objectData={objectData}
							owners={props.owners}
							variables={props.variables}
						/>
					)}

					{props.nodeToEdit.type == "createdeal" && (
						<CreateDeal
							objectData={objectData}
							owners={props.owners}
							customDealFields={props.customDealFields}
							pipelines={props.pipelines}
							variables={props.variables}
						/>
					)}

					{props.nodeToEdit.type == "sleep" && (
						<div className="node-question">
							<div>
								<label>Tempo de delay (segundos):</label>
								<input
									type="number"
									min={0}
									className="inputText"
									value={sleepSeconds}
									onChange={(e) => setSleepSeconds(e.target.value)}
								/>
							</div>
						</div>
					)}

					{props.nodeToEdit.type == "setvar" && (
						<div className="node-question">
							<div>
								<label>Variável:</label>
								<select
									className="inputText"
									value={varToSet}
									onChange={(e) => setvarToSet(e.target.value)}
								>
									{props.variables.map((varx) => (
										<option value={varx.name}>{varx.name}</option>
									))}
								</select>
							</div>
							<div>
								<label>Novo valor:</label>
								<input
									type="text"
									className="inputText"
									value={valueToset}
									onChange={(e) => setvalueToset(e.target.value)}
								/>
							</div>
						</div>
					)}

					{props.nodeToEdit.type == "webhook" && (
						<div className="node-question">
							<div>
								<label>Url:</label>
								<div
									ref={webhookUrl}
									contentEditable="true"
									className="inputText"
									onPaste={handlePaste}
								></div>
							</div>
							<div className="node-question-groupped">
								<label>
									Cabeçalho da requisição:
									<small
										style={{
											float: "right",
											fontWeight: "bold",
											cursor: "pointer",
										}}
										onClick={addWebhookHeader}
									>
										Adicionar
									</small>
								</label>
								{webhookHeaders.map((header) => (
									<div className="input-group-flex">
										<div>
											<label htmlFor="">Chave</label>
											<input
												type="text"
												value={header.label}
												className="inputText"
												onChange={handleWebhookHeaders(header, "label")}
											/>
										</div>
										<div>
											<label htmlFor="">Valor</label>
											<div
												contentEditable="true"
												onPaste={handlePaste}
												className="inputTextArea"
												style={{
													lineHeight: "24px",
												}}
												data-header-id={header.id}
											>
												{header?.value}
											</div>
										</div>
										<button
											title="Apagar Header"
											onClick={() => handleRemoveHeader(header)}
										>
											<FaTrash size={13} />
										</button>
									</div>
								))}

								<span className="spamSmall warning">{messageWarning}</span>
							</div>
							<div>
								<label>
									Corpo da requisição:
									<small
										style={{
											float: "right",
											fontWeight: "bold",
											cursor: "pointer",
										}}
										onClick={formatJson}
									>
										Formatar JSON
									</small>
								</label>
								<div
									ref={webhookBody}
									contentEditable="true"
									onPaste={handlePaste}
									className="inputTextArea"
									style={{
										lineHeight: "24px",
									}}
								></div>
								<span className="spamSmall warning">{messageWarning}</span>
							</div>
							<div>
								<AddVariablesInText
									variables={props.variables}
									showCreateVarButton={true}
									handleModalVariable={props.handleModalVariable}
								></AddVariablesInText>
							</div>
							<div>
								<h4>Salvar respostas como variáveis</h4>
							</div>
							<div>
								<label htmlFor="">
									Variável{" "}
									<BsFillArrowLeftSquareFill></BsFillArrowLeftSquareFill> Campo
									JSON
								</label>
								{inputsSaveResp.map((i) => (
									<div className="webhook-vars">
										<select
											className="inputText"
											id={`var-${i}`}
											value={webhookVars ? webhookVars[i] : ""}
											onChange={(e) => onchangeVarsResps(e.target)}
										>
											<option value=""></option>
											{props.variables.map((varx) => (
												<option value={varx.name}>{varx.name}</option>
											))}
										</select>

										<BsFillArrowLeftSquareFill></BsFillArrowLeftSquareFill>
										<input
											type="text"
											id={`resp-${i}`}
											className="inputText"
											value={webhookResps ? webhookResps[i] : ""}
											onChange={(e) => onchangeVarsResps(e.target)}
										/>

										<FaTrash
											onClick={() => removeInputResp(i)}
											className="remove-var-webhook"
										/>
									</div>
								))}
							</div>
							<div className="questionList">
								<div className="add-new-button" onClick={addInputResp}>
									<div>
										<FaPlus />
									</div>
									<span>Adicionar</span>
								</div>
							</div>
						</div>
					)}

					{props.nodeToEdit.type == "question" && (
						<div className="node-question">
							<div>
								<label>Pergunta:</label>
								<div
									ref={question}
									contentEditable="true"
									className="inputTextArea"
									onPaste={handlePaste}
								></div>
								<AddVariablesInText
									variables={props.variables}
									questionEl={question}
									showCreateVarButton={true}
									handleModalVariable={props.handleModalVariable}
								></AddVariablesInText>
							</div>

							<div>
								<label>Tipo de resposta:</label>
								<select
									className="inputText"
									value={questionTypeResponse}
									onChange={(e) => {
										setQuestionTypeResponse(e.target.value);
										setQuestionResponseActions({});
									}}
								>
									<option value="simple">Simples</option>
									<option value="buttons">Botões</option>
									<option value="list">Lista</option>
								</select>
							</div>

							<div
								className="questionList"
								style={{
									display: questionTypeResponse == "buttons" ? "block" : "none",
								}}
							>
								{Object.keys(questionResponseActions).map((x) => (
									<div>
										<input
											type="text"
											id={`question-btn-${x}`}
											className="inputText"
											value={questionResponseActions[x]}
											onChange={(e) => onchangeRespButtons(e.target)}
										/>
										<button
											onClick={() => {
												removeInputButton(x);
											}}
										>
											<FaTrash />
										</button>
									</div>
								))}

								<div className="add-new-button" onClick={addInputButton}>
									<div>
										<FaPlus />
									</div>
									<span>Adicionar novo botão</span>
								</div>
							</div>

							<div
								className="questionList"
								style={{
									display: questionTypeResponse == "list" ? "block" : "none",
								}}
							>
								<div className="input-group list-title">
									<label htmlFor="">Texto para seleção da lista</label>
									<input
										type="text"
										className="inputText"
										placeholder="Ex: Clique para selecionar uma opção"
										value={questionResponseActions.buttonTitle}
										onChange={(e) => onchangeButtonSectionTitle(e.target)}
									/>
								</div>
								<hr />
								{questionResponseActions.sections &&
									Object.keys(questionResponseActions.sections).map((x, i) => (
										<div>
											<div className="item-section">
												<div className="input-group">
													<label>Sessão:</label>
													<div className="full-input">
														<input
															type="text"
															id={`section-${x}`}
															className="inputText"
															value={questionResponseActions.sections[x].title}
															onChange={(e) => onchangeSectionTitle(e.target)}
															placeholder="Ex: Financeiro:"
														/>
														<button>
															<FaTrash
																onClick={() => {
																	removeSession(x);
																}}
															/>
														</button>
													</div>
												</div>

												<div className="question-sections">
													{Object.keys(
														questionResponseActions.sections[x].items || {}
													).map((it) => (
														<div>
															<div className="input-group">
																<label htmlFor="">Nome do item:</label>
																<div className="full-input">
																	<input
																		type="text"
																		id={
																			questionResponseActions.sections[x].items[
																				it
																			].id
																		}
																		className="inputText"
																		style={{ width: "150px" }}
																		value={
																			questionResponseActions.sections[x].items[
																				it
																			].title
																		}
																		onChange={(e) =>
																			onchangeButtonTitle(e.target)
																		}
																		placeholder="Ex: Conta"
																	/>
																	<button>
																		<FaTrash
																			onClick={() => {
																				removeSessionItem(x, it);
																			}}
																		/>
																	</button>
																</div>
															</div>
															<div className="input-group">
																<label htmlFor="">Descrição do item:</label>
																<input
																	type="text"
																	id={
																		questionResponseActions.sections[x].items[
																			it
																		].id
																	}
																	className="inputText"
																	style={{
																		height: "10px",
																		fontSize: "12px",
																	}}
																	value={
																		questionResponseActions.sections[x].items[
																			it
																		].description
																	}
																	onChange={(e) =>
																		onchangeButtonDescription(e.target)
																	}
																	placeholder="Ex: Desejo falar sobre minha conta"
																/>
															</div>
															<hr></hr>
														</div>
													))}
												</div>
												<div
													className="section-add-item add-new-button"
													onClick={() => {
														addItemSectionQuestion(x);
													}}
												>
													<div>
														<FaPlus />
													</div>
													<span>Adicionar item</span>
												</div>
											</div>
										</div>
									))}
								<div
									className="add-new-button"
									onClick={addSectionListQuestion}
								>
									<div>
										<FaPlus />
									</div>
									<span>Adicionar sessão</span>
								</div>
							</div>

							<div>
								<label>Salve as respostas na variável:</label>
								<select
									className="inputText"
									value={questionVar}
									onChange={(e) => setquestionVar(e.target.value)}
								>
									<option value=""></option>
									{props.variables.map((varx) => (
										<option value={varx.name}>{varx.name}</option>
									))}
								</select>
								<span className="spamSmall">
									*Se a variavel for igual a "email", a validação acontecerá
									automaticamente
								</span>
							</div>
						</div>
					)}

					{props.nodeToEdit.type == "conditional" && (
						<div className="node-question">
							<div>
								<label>Selecione uma variável para comparar</label>
								<select
									className="inputText"
									value={condVar}
									onChange={(e) => setcondVar(e.target.value)}
								>
									{props.variables.map((varx) => (
										<option value={varx.name}>{varx.name}</option>
									))}
								</select>
							</div>

							<div>
								<label>Comparação</label>
								<select
									className="inputText"
									value={condType}
									onChange={(e) => setcondType(e.target.value)}
								>
									{Object.keys(nameConditions).map((it) => (
										<option value={it}>{nameConditions[it]}</option>
									))}
								</select>
							</div>

							<div className={!condValueVisible ? "hidden" : ""}>
								<label>Valor</label>
								<div
									ref={condValue}
									contentEditable="true"
									className="inputText"
									onPaste={handlePaste}
								></div>
								<AddVariablesInText
									variables={props.variables}
								></AddVariablesInText>
							</div>
						</div>
					)}

					{props.nodeToEdit.type == "jump" && (
						<div className="node-question">
							<div>
								<label>Selecione uma variável para comparar</label>
								<select
									className="inputText"
									value={jumpVar}
									onChange={(e) => setjumpVar(e.target.value)}
								>
									{props.variables.map((varx) => (
										<option value={varx.name}>{varx.name}</option>
									))}
								</select>
							</div>
							<div className="questionList">
								{Object.keys(jumps).map((x, i) => (
									<div className="input-full">
										<input
											title={jumps[x]}
											type="text"
											id={`out-${x}`}
											className="inputText"
											style={{ width: "100px" }}
											value={jumps[x]}
											onChange={(e) => onchangeJump(e.target)}
										/>
										<button onClick={() => removeJump(x)}>
											<FaTrash />
										</button>
									</div>
								))}

								<div className="add-new-button" onClick={addInputJump}>
									<div>
										<FaPlus />
									</div>
									<span>Adicionar novo jump</span>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="modalBottom">
					<button
						onClick={() => {
							props.saveNode(props.nodeToEdit);
							props.handleModalEdit();
						}}
						className="btnModal btnCancel"
					>
						Cancelar
					</button>
					<button onClick={saveNode} className="btnModal btnSaveModal">
						Salvar
					</button>
				</div>
			</div>
		</>
	);
}

export default Modal;
